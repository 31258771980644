import ornMin1 from "@/assets/images/orn-min-1.png"
import ornMin14 from "@/assets/images/orn-min-14.png"
import ornMin34 from "@/assets/images/orn-min-34.png"
import ornMin47 from "@/assets/images/orn-min-47.png"
import quoteFrame from "@/assets/images/quote-frame.png"
import ornMin11 from "@/assets/images/orn-min-11.png"
import ornMin54 from "@/assets/images/orn-min-54.png"
import ornMin13 from "@/assets/images/orn-min-13.png"
import ornMin17 from "@/assets/images/orn-min-17.png"   
const index = () => {
  return (
    <section className="quote-wrap ">    
    <div className="quote-inner">

        <div className="ornaments-wrapper">
            <div className="quote--orn wrapper">
                <div className="quote--orn quote-top-1">
                    <div className="quote--orn quote-top-6">
                        <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="900">
                            <img src={ornMin1} alt="Ornament"/>   
                        </div>
                    </div>
                    <div className="quote--orn quote-top-5">
                        <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="900">
                            <img src={ornMin1} alt="Ornament"/>
                        </div>
                    </div>
                    <div className="quote--orn quote-top-4">
                        <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="700">
                            <img src={ornMin14} alt="Ornament"/>
                        </div>
                    </div>
                    <div className="quote--orn quote-top-3">
                        <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="700">
                            <img src={ornMin14} alt="Ornament"/>
                        </div>
                    </div>
                    <div className="quote--orn quote-top-2">
                        <div className="image-wrap" data-aos="fade-right" data-aos-duration="1200"
                            data-aos-delay="300">
                            <img src={ornMin34} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="500">
                        <img src={ornMin47} alt="Ornament"/>
                    </div>
                </div>
                <div className="quote-title-wrapper">
                    <h1 className="quote-title">Ar - Rum : <span>21</span></h1>
                </div>
                <div className="quote--orn frame">

                    <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
                        <img src={quoteFrame} alt="Ornament"/>
                    </div>

                    <div className="quote--orn quote-bottom-1">

                        <div className="quote--orn quote-bottom-7">
                            <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                                data-aos-delay="200">
                                <img src={ornMin11} alt="Ornament"/>
                            </div>
                        </div>
                        <div className="quote--orn quote-bottom-6">
                            <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                                data-aos-delay="200">
                                    <img src={ornMin54} alt="Ornament"/>
                            </div>
                        </div>
                        <div className="quote--orn quote-bottom-5">
                            <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                                data-aos-delay="200">
                                    <img src={ornMin13} alt="Ornament"/>
                            </div>
                        </div>

                        <div className="quote--orn quote-bottom-4">
                            <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                                data-aos-delay="200">
                                <img src={ornMin11} alt="Ornament"/>
                            </div>
                        </div>
                        <div className="quote--orn quote-bottom-3">
                            <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                                data-aos-delay="200">
                                <img src={ornMin54} alt="Ornament"/>
                            </div>
                        </div>
                        <div className="quote--orn quote-bottom-2">
                            <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                                data-aos-delay="200">
                                <img src={ornMin13} alt="Ornament"/>
                            </div>
                        </div>
                        <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200"
                            data-aos-delay="200">
                            <img src={ornMin17} alt="Ornament"/>
                        </div>

                    </div>
                </div>

                <div className="quote">
                    <p className="quote-caption" data-aos="fade-up" data-aos-duration="1000">Di antara tanda-tanda
                        (kebesaran)-Nya ialah bahwa Dia menciptakan pasangan-pasangan untukmu dari (jenis)
                        dirimu sendiri agar kamu merasa tenteram kepadanya. Dia menjadikan di antaramu rasa
                        cinta dan kasih sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat
                        tanda-tanda (kebesaran Allah) bagi kaum yang berpikir. (QS Ar-Rum: 21)</p>
                </div>
            </div>
        </div>

    </div>
</section>
  )
}

export default index