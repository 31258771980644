import useTamuStore from "@/stores/tamuStore";
import { db, doc, setDoc } from "@/utility/firebase_options";
import { QRCodeSVG } from "qrcode.react";
import logo from "@/assets/images/logo-cover.png";
import love from "@/assets/images/love.png";
const index = () => {
  const { tamu } = useTamuStore();
  const updateFirestoreHadir = async (hadirStatus: boolean) => {
    if (!tamu.id) return;
    const tamuRef = doc(db, "tamu", tamu.id);
    await setDoc(tamuRef, { confirmedHadir: hadirStatus }, { merge: true });
  };

  const handleHadirClick = async () => {
    await updateFirestoreHadir(true);
  };

  const handleTidakHadirClick = async () => {
    await updateFirestoreHadir(false);
  };
  const handleQRCodeClick = () => {
    const qrCodeElement = document.getElementById("qr-code");
    if (qrCodeElement) {
      if (qrCodeElement.requestFullscreen) {
        qrCodeElement.requestFullscreen();
      } else if ((qrCodeElement as any).mozRequestFullScreen) {
        // Firefox
        (qrCodeElement as any).mozRequestFullScreen();
      } else if ((qrCodeElement as any).webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        (qrCodeElement as any).webkitRequestFullscreen();
      } else if ((qrCodeElement as any).msRequestFullscreen) {
        // IE/Edge
        (qrCodeElement as any).msRequestFullscreen();
      }
    }
  };

  return (
    <section className="wedding-wish-wrap" data-template="">
      <div className="wedding-wish-inner">
        <div className="wedding-wish-head">
          <h1
            className="wedding-wish-title !wi-text-[3.5rem]"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            Konfirmasi Kehadiran
          </h1>
          <p
            className="wedding-wish-description !wi-mt-4"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Kami tidak sabar menunggu hari pernikahan kami bersama{" "}
            Bapak/Ibu/Saudara/i {tamu.displayName}, mohon konfirmasi kehadiran
            melalui tombol di bawah ini.
          </p>
          {tamu.confirmedHadir && !tamu.hadir && (
            <div className="wi-flex wi-flex-col wi-items-center wi-justify-center wi-gap-4 wi-mt-4 wi-w-full">
              <div
                data-aos="fade-up"
                data-aos-duration="1000"
                className="wi-relative wi-inline-block wi-bg-white wi-rounded-md wi-p-2"
              >
                <QRCodeSVG
                  id="qr-code" // Add an ID for the QR code element
                  onClick={handleQRCodeClick} // Add onClick handler
                  value={tamu.id || ""}
                  size={256} // Adjust size for better responsiveness
                  style={{ display: "block", maxWidth: "100%", height: "auto" }} // Ensure QR code is responsive
                />
                {logo && (
                  <div>
                    <img
                      src={logo}
                      alt="Logo"
                      loading="lazy"
                      style={{
                        backgroundColor: "white",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "25%", // Ukuran logo
                        height: "25%", // Ukuran logo
                        borderRadius: "50%", // Jika ingin logo berbentuk lingkaran
                      }}
                    />
                  </div>
                )}
              </div>
              <p
                data-aos="fade-up"
                data-aos-duration="1000"
                className="!wi-text-green-600 !wi-font-bold wi-my-4"
              >
                Saat menghadiri acara, mohon tunjukkan QR code ini kepada
                penerima tamu untuk di-scan
              </p>
            </div>
          )}
          {tamu.confirmedHadir && tamu.hadir && (
            <div
              data-aos="fade-up"
              data-aos-duration="1000"
              className="wi-flex wi-flex-col wi-items-center wi-justify-center wi-my-4"
            >
              <p className="!wi-text-[1.25rem] !wi-text-green-600 !wi-font-bold">
                Terima kasih telah hadir di acara pernikahan kami
              </p>
              <img
                src={love}
                alt="Thank You"
                className="wi-mt-4 wi-w-32 wi-h-32 wi-object-contain wi-rounded-full"
              />
              <p className="!wi-text-secondary wi-mt-2">
                We appreciate your presence and blessings.
              </p>
            </div>
          )}
          {!tamu.confirmedHadir && !tamu.hadir && (
            <button
              onClick={handleHadirClick}
              type="button"
              data-aos="fade-up"
              data-aos-duration="1200"
              className="  wi-py-3 wi-mt-4 !wi-bg-secondary wi-text-white wi-w-full wi-rounded-full"
              data-last=""
            >
              Akan Hadir
            </button>
          )}
          {tamu.confirmedHadir && !tamu.hadir && (
            <button
              onClick={handleTidakHadirClick}
              type="button"
              data-aos="fade-up"
              data-aos-duration="1200"
              className="  wi-py-3 wi-mt-4 !wi-bg-red-500 wi-text-white wi-w-full wi-rounded-full"
              data-last=""
            >
              Tidak Hadir
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default index;
