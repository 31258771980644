import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import { MediaPlayer, MediaProvider } from "@vidstack/react";   
import logo from "@/assets/images/logo-cover.png";
import prewedding from "@/assets/videos/prewedding.mp4";
import {
  defaultLayoutIcons,
  DefaultVideoLayout,
} from "@vidstack/react/player/layouts/default"; 
const index = () => {
  const images = Array.from({ length: 16 }, (_, index) => ({
    src: `https://placehold.co/600x800/591d1f/e4bd75?text=Prewedding+${
      index + 1
    }`,
    subHtml: `<h4>Prewedding Image ${
      index + 1
    }</h4><p>This is a placeholder image for prewedding.</p>`,
    pinterestText: `Pin it ${index + 1}`,
    tweetText: `lightGallery slide ${index + 1}`,
  }));

  return (
    <section className="wedding-wish-wrap">
      <div className="wedding-wish-inner !wi-max-w-[1200px] wi-mx-auto">
        <div className="wedding-wish-head">
          <h1
            className="wedding-wish-title !wi-text-[3.5rem]"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            Our Gallery
          </h1>
        </div>
        <MediaPlayer
      title={"Prewedding Video"}  
      className="wi-mt-4"
      poster={logo}
      controls
      src={prewedding}  
   
      onLoadedMetadata={()=>{}}
      onError={()=>{}}
    >
      <MediaProvider />
      <DefaultVideoLayout
        thumbnails={logo}
        icons={defaultLayoutIcons}
      />
    </MediaPlayer>
        <LightGallery
          plugins={[lgZoom, lgVideo]}
          mode="lg-fade"
          pager={false}
          thumbnail={true}
          galleryId={"nature"}
          autoplayFirstVideo={false}
          elementClassNames={
            "wi-w-full wi-grid wi-grid-cols-2 sm:wi-grid-cols-3 md:wi-grid-cols-4 wi-gap-2 wi-mt-4"
          } // Updated prefix
          mobileSettings={{
            controls: false,
            showCloseIcon: false,
            download: false,
            rotate: false,
          }}
        >
          {images.map(
            (
              image,
              index // Pemetaan array gambar
            ) => (
              <a
                key={index}
                data-pinterest-text={image.pinterestText}
                data-tweet-text={image.tweetText}
                data-src={image.src}
                data-sub-html={image.subHtml}
              >
                <img
                  className="wi-object-covert-cover wi-rounded-lg " // Updated prefix
                  src={image.src} // Mengubah ukuran gambar
                  style={{ width: "100%", height: "100%" }}
                />
              </a>
            )
          )}
        </LightGallery>
      </div>
    </section>
  );
};

export default index;
