import awan from "@/assets/images/awan.png";
import ornMin50 from "@/assets/images/orn-min-50.png";
import ornMin2 from "@/assets/images/orn-min-2.png";
import ornMin44 from "@/assets/images/orn-min-44.png";
import ornMin41 from "@/assets/images/orn-min-41.png";
import ornMin3 from "@/assets/images/orn-min-3.png";
import ornMin5 from "@/assets/images/orn-min-5.png";
import ornMin36 from "@/assets/images/orn-min-36.png";
import ornMin37 from "@/assets/images/orn-min-37.png";
import ornMin1 from "@/assets/images/orn-min-1.png";
import ornMin55 from "@/assets/images/orn-min-55.png";
import logoCover from "@/assets/images/logo-cover.png";
import ornMin6 from "@/assets/images/orn-min-6.png";
import ornMin7 from "@/assets/images/orn-min-7.png";
import ornMin8 from "@/assets/images/orn-min-8.png";
import ornMin9 from "@/assets/images/orn-min-9.png";
import useTamuStore from "@/stores/tamuStore";

const index = () => {
  const { tamu } = useTamuStore();
  return (
    <section className="primary-pane">
      <div className="inner">
        <div className="ornaments-wrapper">
          <div className="footnote--left-right-orn awan-1">
            <div
              className="image-wrap"
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <img src={awan} alt="Ornament" />
            </div>

            <div className="footnote--left-right-orn awan-2">
              <div
                className="image-wrap"
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img src={awan} alt="Ornament" />
              </div>
            </div>
          </div>

          <div className="orn-primary center-1">
            <div className="orn-primary center-4">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin50} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary center-3">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin50} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary center-2">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin50} alt="Ornament" />
              </div>
            </div>
            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              <img src={ornMin50} alt="Ornament" />
            </div>
          </div>

          <div className="orn-primary right-1">
            <div className="orn-primary right-9">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="1200"
              >
                <img src={ornMin2} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary right-8">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="900"
              >
                <img src={ornMin44} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary right-7">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="900"
              >
                <img src={ornMin41} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary right-6">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin3} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary right-5">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin5} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary right-4">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin36} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary right-3">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin37} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary right-2">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin1} alt="Ornament" />
              </div>
            </div>
            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              <img src={ornMin55} alt="Ornament" />
            </div>
          </div>

          <div className="orn-primary left-1">
            <div className="orn-primary left-9">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="1200"
              >
                <img src={ornMin2} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary left-8">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="900"
              >
                <img src={ornMin44} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary left-7">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="900"
              >
                <img src={ornMin41} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary left-6">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin3} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary left-5">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin5} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary left-4">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin36} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary left-3">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin37} alt="Ornament" />
              </div>
            </div>
            <div className="orn-primary left-2">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin1} alt="Ornament" />
              </div>
            </div>
            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="300"
            >
              <img src={ornMin55} alt="Ornament" />
            </div>
          </div>

          <div className="primary-pane-details">
            <div className="orn-primary logo">
              <div
                className="image-wrap"
                data-aos="fade-down"
                data-aos-duration="1200"
                data-aos-delay="600"
              >
                <img src={logoCover} alt="Ornament" />
              </div>
            </div>
            <div className="primary-pane-details--content">
              <h1
                className="primary-pane-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-anchor=".primary-pane-details"
              >
            <span className="!wi-mr-3">Rizka</span> <span>&amp;</span>
            <span className="!wi-ml-3">Adhitya</span>
              </h1>

              <div
                className="link-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
                data-aos-anchor=".primary-pane-details"
              ></div>
              <p className="!wi-text-[1.5rem]">Kepada Yth, Bapak/Ibu/Saudara/I.</p>
              <p className="!wi-text-[1.5rem]">{tamu.displayName}</p>
              <span className="wi-block wi-text-xs wi-text-primary">
                *Mohon maaf apabila ada kesalahan penulisan nama dan gelar
              </span>
            </div>
          </div>

          <section
            className="effects nanda"
            data-aos="zoom-out"
            data-aos-duration="2000"
            data-aos-delay="500"
          >
            <div>
              <img src={ornMin6} alt="" />
            </div>
            <div>
              <img src={ornMin7} alt="" />
            </div>
            <div>
              <img src={ornMin8} alt="" />
            </div>
            <div>
              <img src={ornMin9} alt="" />
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default index;
