import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Radio } from "antd";
import { useEffect, useState } from "react";

const FilterTamuModal = ({
  filterOption,
  setFilterOption,
  isModalFilterOpen,
  setIsModalFilterOpen,
}: {
  filterOption: string;
  setFilterOption: (value: string) => void;
  isModalFilterOpen: boolean;
  setIsModalFilterOpen: (value: boolean) => void;
}) => {
  const [tempFilterOption, setTempFilterOption] = useState(null);
  const handleOkFilter = () => {
    setFilterOption(JSON.parse(JSON.stringify(tempFilterOption)));
    setIsModalFilterOpen(false);
  };
  const handleCancelFilter = () => {
    setIsModalFilterOpen(false);
  };
  useEffect(() => {
    if (isModalFilterOpen) {
      setTempFilterOption(JSON.parse(JSON.stringify(filterOption)));
    } else {
      setTempFilterOption(null);
    }
  }, [filterOption, isModalFilterOpen]);
  return (
    <Modal
      title={<p className="!wi-text-white !wi-text-[1.5rem]">Filter Tamu </p>}
      open={isModalFilterOpen}
      onOk={handleOkFilter}
      onCancel={handleCancelFilter}
      footer={null}
      width={400}
      closeIcon={<CloseOutlined className="wi-text-white" />}
      classNames={{
        body: "!wi-bg-tertiary",
        content: "!wi-bg-tertiary",
        header: "!wi-bg-tertiary !wi-text-white",
      }}
    >
      <Radio.Group
        className="wi-flex wi-flex-col wi-gap-2 "
        buttonStyle="outline"
        onChange={(e) => {
          setTempFilterOption(e.target.value);
        }}
        value={tempFilterOption}
      >
        {/* <Radio value="tidakAdaStatus">
        <span className="!wi-text-base !wi-text-white">
          Belum Ada Status
        </span>
      </Radio> */}
        <Radio value="temanRizka">
          <span className="!wi-text-base !wi-text-white">Teman Rizka</span>
        </Radio>
        <Radio value="temanAdhitya">
          <span className="!wi-text-base !wi-text-white">
            Teman Adhitya
          </span>
        </Radio>
        <Radio value="keluargaRizka">
          <span className="!wi-text-base !wi-text-white">
            Keluarga Rizka
          </span>
        </Radio>
        <Radio value="keluargaAdhitya">
          <span className="!wi-text-base !wi-text-white">
            Keluarga Adhitya
          </span>
        </Radio>
        <Radio value="undanganBelumTerkirim">
          <span className="!wi-text-base !wi-text-white">
            Undangan Belum Dikirim
          </span>
        </Radio>
        <Radio value="undanganTerkirim">
          <span className="!wi-text-base !wi-text-white">
            Undangan Sudah Dikirim
          </span>
        </Radio>
        <Radio value="hadir">
          <span className="!wi-text-base !wi-text-white">Hadir</span>
        </Radio>
        <Radio value="tidakHadir">
          <span className="!wi-text-base !wi-text-white">Tidak Hadir</span>
        </Radio>
        <Radio value="all">
          <span className="!wi-text-base !wi-text-white">Semua</span>
        </Radio>
      </Radio.Group>
      <Button className="wi-w-full wi-mt-4" type="primary" onClick={handleOkFilter}>
        Terapkan
      </Button>
    </Modal>
  );
};

export default FilterTamuModal;
