import ornMin24 from '@/assets/images/orn-min-24.png'

const index = () => {
  return (
    <section className="couple-wrap">
      <div className="couple">
        <div className="couple-head">
          <h1 className="couple-title" data-aos="zoom-in" data-aos-duration="1000">The Wedding of</h1>
          <p className="couple-description" data-aos="fade-up" data-aos-duration="1000">
            Dengan berkah dan rahmat dari Allah SWT. Dengan hormat, kami mengundang Anda untuk menghadiri pernikahan dari
          </p>
        </div>

        <div className="couple-body bride-first">
          <div className="couple-info groom">
            <div className="couple-details">
              <h1 className="couple-nickname" data-aos="fade-up" data-aos-duration="1000">Adhitya</h1>
              <h2 className="couple-name" data-aos="fade-up" data-aos-duration="1000">Adhitya Rachman Hidayatullah</h2>
              <p className="couple-parents" data-aos="fade-up" data-aos-duration="1000">
                Putra Pertama dari
                <br />
                Bapak Sukarni Anto <br />
                &amp; Ibu Astutik
              </p>
              <div className="couple-link-wrap" data-aos="fade-up" data-aos-duration="1000">
                <a href="https://www.instagram.com/adhityarachmanh" target="_blank" className="couple-link hover:wi-bg-transparent">
                  <i className="fab fa-instagram"></i> @adhityarachmanh
                </a>
              </div>
            </div>
          </div>

          <div className="separator-wrap">
            <div className="ornaments-wrapper">
              <div className="orn-couple--center-right">
                <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                  <img src={ornMin24} alt="Ornament" />
                </div>
              </div>

              <div className="orn-couple--center-left">
                <div className="image-wrap" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="300">
                  <img src={ornMin24} alt="Ornament" />
                </div>
              </div>
            </div>
            <div className="separator" data-aos="zoom-in" data-aos-duration="1500">
              <h2 className="couple-separator">&amp;</h2>
            </div>
          </div>

          <div className="couple-info bride">
            <div className="couple-details">
              <h1 className="couple-nickname" data-aos="fade-up" data-aos-duration="1000">Rizka</h1>
              <h2 className="couple-name" data-aos="fade-up" data-aos-duration="1000">Rizka Agustin</h2>
              <p className="couple-parents" data-aos="fade-up" data-aos-duration="1000">
                Putri ketiga dari
                <br />
                Bapak H. Zulbachri Roeslan (Alm.) <br />
                &amp; Ibu Nurul Inayati
              </p>
              <div className="couple-link-wrap" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <a href="https://www.instagram.com/rzkaagustin" target="_blank" className="couple-link hover:wi-bg-transparent">
                  <i className="fab fa-instagram"></i> @rzkaagustin
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;