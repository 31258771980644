import TamuModel from "@/types/TamuModel";
import { Button, Drawer } from "antd";
import LihatWishModal from "./LiahtWishModal";
import { useState } from "react";

const SummaryInfo = ({
  isModalSummaryInfoOpen,
  setIsModalSummaryInfoOpen,
  tamu,
}: {
  isModalSummaryInfoOpen: boolean;
  setIsModalSummaryInfoOpen: (value: boolean) => void;
  tamu: TamuModel[];
}) => {
  const [isModalLihatWishOpen, setIsModalLihatWishOpen] = useState(false);
  const onClose = () => {
    setIsModalSummaryInfoOpen(false);
  };

  return (
    <>
      <Drawer
        title={
          <div className="wi-flex wi-items-center">
            <p className="!wi-text-white !wi-text-[1.5rem]">Info Tamu</p>
          </div>
        }
        placement="bottom"
        closable={false}
        onClose={onClose}
        open={isModalSummaryInfoOpen}
        key="bottom"
        classNames={{
          body: "!wi-bg-tertiary",
          content: "!wi-bg-tertiary",
          header: "!wi-bg-tertiary !wi-text-white",
        }}
      >
        <div className="wi-flex wi-flex-col  wi-w-full wi-mb-2  wi-p-2 wi-gap-2">
          <div className="wi-flex wi-flex-col wi-gap-2 wi-w-full">
            <p className="!wi-text-lg !wi-font-bold !wi-text-white !wi-font-buenard">
              Total Keseluruhan
            </p>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Undang:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {tamu.length}
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Pax:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {tamu.reduce((acc, tamu) => acc + tamu.pax, 0)}
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col wi-gap-2 wi-w-full">
            <p className="!wi-text-lg !wi-font-bold !wi-text-white !wi-font-buenard">
              Keluarga
            </p>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Keluarga Adhitya:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "1" && tamu.type === "1"
                  ).length
                }
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Keluarga Rizka:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "2" && tamu.type === "1"
                  ).length
                }
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col wi-gap-2 wi-w-full">
            <p className="!wi-text-lg !wi-font-bold !wi-text-white !wi-font-buenard">
              Teman
            </p>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Teman Adhitya:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "1" && tamu.type === "2"
                  ).length
                }
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Teman Rizka:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {
                  tamu.filter(
                    (tamu) => tamu.tamuDari === "2" && tamu.type === "2"
                  ).length
                }
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col wi-gap-2 wi-w-full">
            <p className="!wi-text-lg !wi-font-bold !wi-text-white !wi-font-buenard">
              Kehadiran
            </p>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Hadir:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {
                  tamu.filter((tamu) => tamu.confirmedHadir && tamu.hadir)
                    .length
                }
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Tidak Hadir:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {
                  tamu.filter((tamu) => !tamu.confirmedHadir && !tamu.hadir)
                    .length
                }
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col wi-gap-2 wi-w-full">
            <p className="!wi-text-lg !wi-font-bold !wi-text-white !wi-font-buenard">
              Status Undangan
            </p>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Undangan Belum Dikirim:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {tamu.filter((tamu) => !tamu.undanganTerkirim).length}
              </p>
            </div>
            <div className="wi-flex wi-flex-row wi-items-center wi-gap-2 wi-justify-between wi-w-full">
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                Undangan Dikirim:
              </p>
              <p className="!wi-text-base wi-font-bold !wi-text-white !wi-font-buenard">
                {tamu.filter((tamu) => tamu.undanganTerkirim).length}
              </p>
            </div>
          </div>
          <div className="wi-flex wi-flex-col wi-gap-2 wi-w-full">
          <p className="!wi-text-lg !wi-font-bold !wi-text-white !wi-font-buenard">
              Ucapan
            </p>
            <Button
              type="primary"
              onClick={() => setIsModalLihatWishOpen(true)}
            >
              Lihat
            </Button>
          </div>
        </div>
      </Drawer>
      <LihatWishModal
        isModalLihatWishOpen={isModalLihatWishOpen}
        setIsModalLihatWishOpen={setIsModalLihatWishOpen}
        tamu={null}
      />
    </>
  );
};

export default SummaryInfo;
