import AOS from "aos";
import { useEffect, useState } from "react";
import PrimaryPane from "@/components/primary_pane";
import TopCover from "@/components/secondary_pane/top_cover";
import CoverWrap from "@/components/secondary_pane/cover_wrap";
import MusicOuter from "@/components/music_outer";
import CoupleWrap from "@/components/secondary_pane/couple_wrap";
import SavedateAgenda from "@/components/secondary_pane/savedate_agenda";
import WeddingWish from "@/components/secondary_pane/wedding_wish";
import useMusicStore from "@/stores/musicStore";
import music from "@/assets/audio/music2.mp3";
import WeddingGift from "@/components/secondary_pane/wedding_gift";
import Quote from "@/components/secondary_pane/quote";
import Footnote from "@/components/secondary_pane/footnote";
import { db, doc, onSnapshot } from "@/utility/firebase_options";
import { useLocation } from "react-router-dom";
import useTamuStore from "./stores/tamuStore";
import TamuModel from "./types/TamuModel";
import Rsvp from "@/components/secondary_pane/rsvp";
import Gallery from "@/components/secondary_pane/gallery";
function App() {
  const [audio] = useState(() => {
    const audioInstance = new Audio(music);
    audioInstance.loop = true;
    return audioInstance;
  });
  const { playing } = useMusicStore();
  const { setTamu } = useTamuStore();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const to = query.get("to");

  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }

    return () => {
      audio.pause(); 
    };
  }, [playing]);

  useEffect(() => {
    // window.scrollTo({ top: 0, behavior: "smooth" });
   
    AOS.init({
      disable: false,
      startEvent: "DOMContentLoaded",
      initClassName: "aos-init",
      animatedClassName: "aos-animate",
      useClassNames: false,
      disableMutationObserver: false,
      debounceDelay: 0,
      throttleDelay: 0,
      offset: 10,
      delay: 0,
      duration: 400,
      easing: "ease",
      once: true,
      mirror: false,
      anchorPlacement: "top-bottom",
    });
  
  }, []);

  useEffect(() => {
    if (to) {
      // {{ edit_4 }}
      const docRef = doc(db, "tamu", to);
      const unsubscribe = onSnapshot(
        docRef,
        (doc) => {
          if (doc.exists()) {
            const data = { id: doc.id, ...doc.data() };
            setTamu(data as TamuModel);
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error getting document:", error);
        }
      );

      // Cleanup subscription on unmount
      return () => unsubscribe();
    }
  }, [to]);
  return (
    <>
      <section  className="kat-page__side-to-side">
        <PrimaryPane />
        <section className="secondary-pane">
          <TopCover />
          <CoverWrap />
          <CoupleWrap />
         
          <SavedateAgenda />
          <Rsvp />
          <WeddingWish />
          
          <WeddingGift />
          <Gallery />
          <Quote />
          <Footnote />
        </section>
      </section>
      <MusicOuter />
 
    </>
  );
}

export default App;
