// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// Import Firestore
import {
  getFirestore,
  collection,
  onSnapshot,
  getDoc,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
} from "firebase/firestore"; // {{ edit_1 }}

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnpDOBTYLwKlqvMtijQ6sFrKh1hFnXhG4",
  authDomain: "rizka-rachman.firebaseapp.com",
  projectId: "rizka-rachman",
  storageBucket: "rizka-rachman.firebasestorage.app",
  messagingSenderId: "622404382331",
  appId: "1:622404382331:web:ed9b67fe48193f78597cde",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app); // {{ edit_2 }}
export {
  db,
  collection,
  onSnapshot,
  getDoc,
  addDoc,
  setDoc,
  doc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
};
