import TamuModel from "@/types/TamuModel";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { QRCodeSVG } from "qrcode.react";
import logo from "@/assets/images/logo-cover.png";

const LihatQRCodeModal = ({
  isModalLihatQRCodeOpen,
  setIsModalLihatQRCodeOpen,
  tamu,
}: {
  isModalLihatQRCodeOpen: boolean;
  setIsModalLihatQRCodeOpen: (value: boolean) => void;
  tamu: TamuModel;
}) => {
  const handleOkLihatQRCode = () => {
    setIsModalLihatQRCodeOpen(false);
  };

  const handleCancelLihatQRCode = () => {
    setIsModalLihatQRCodeOpen(false);
  };
  const downloadQRCode = (tamu: TamuModel) => {
    const svgElement = document.getElementById(
      "qr-code"
    ) as unknown as SVGSVGElement;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const DOMURL = window.URL || window.webkitURL || window;
    const img = new Image();
    const svg = new Blob([svgString], { type: "image/svg+xml;charset=utf-8" });
    const url = DOMURL.createObjectURL(svg);

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx?.drawImage(img, 0, 0);
      DOMURL.revokeObjectURL(url);

      const pngImg = canvas.toDataURL("image/png");

      const a = document.createElement("a");
      a.href = pngImg;
      a.download = `QR Code -${tamu.displayName}.png`; // Nama file yang akan diunduh
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    img.src = url;
  };
  return (
    <Modal
      title={<p className="!wi-text-white !wi-text-[1.5rem]"> QR Code</p>}
      open={isModalLihatQRCodeOpen}
      onOk={handleOkLihatQRCode}
      onCancel={handleCancelLihatQRCode}
      footer={null}
      width={400}
      closeIcon={<CloseOutlined className="wi-text-white" />}
      classNames={{
        body: "!wi-bg-tertiary",
        content: "!wi-bg-tertiary",
        header: "!wi-bg-tertiary !wi-text-white",
      }}
    >
      {isModalLihatQRCodeOpen && (
        <div className="wi-flex wi-flex-col wi-justify-center wi-items-center wi-w-full wi-gap-4">
          <div
            className="wi-relative wi-inline-block wi-bg-white wi-rounded-md wi-p-2"
           
          >
            <QRCodeSVG
              id="qr-code" // Add an ID for the QR code element
              value={tamu.id || ""}
              size={350}
              style={{ display: "block", maxWidth: "100%", height: "auto" }} // Ensure QR code is responsive
            />
            {logo && (
              <div>
                <img
                  src={logo}
                  alt="Logo"
                  loading="lazy"
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "25%", // Ukuran logo
                    height: "25%", // Ukuran logo
                    borderRadius: "50%", // Jika ingin logo berbentuk lingkaran
                  }}
                />
              </div>
            )}
          </div>
          <Button
            type="primary"
            className="!wi-bg-blue-500 hover:!wi-bg-blue-500/80 wi-w-full"
            onClick={() => downloadQRCode(tamu)}
          >
            Download QR Code
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default LihatQRCodeModal;
