export const listRekening = [
  {
    name: "Bank BCA",
    number: "0954119722", 
    owner: "Rizka Agustin",
  },
  {
    name: "Bank BRI",
    number: "012201165162509",
    owner: "Adhitya Rachman Hidayatullah",
  },
];
