import { create } from 'zustand';   

interface MusicStore {
  playing: boolean;
  playMusic: () => void;
  pauseMusic: () => void;
}

const useMusicStore = create<MusicStore>((set: any) => ({       
  playing: false,
  playMusic: () => set((_: any) => ({ playing: true })),  
  pauseMusic: () => set((_: any) => ({ playing: false })),
}));

export default useMusicStore;