import awan from "@/assets/images/awan.png";
import ornMin50 from "@/assets/images/orn-min-50.png";
import ornMin2 from "@/assets/images/orn-min-2.png";
import ornMin44 from "@/assets/images/orn-min-44.png";
import ornMin41 from "@/assets/images/orn-min-41.png";
import ornMin42 from "@/assets/images/orn-min-42.png";
import ornMin3 from "@/assets/images/orn-min-3.png";
import ornMin5 from "@/assets/images/orn-min-5.png";
import ornMin4 from "@/assets/images/orn-min-4.png";
import ornMin37 from "@/assets/images/orn-min-37.png";
import ornMin1 from "@/assets/images/orn-min-1.png";
import ornMin35 from "@/assets/images/orn-min-35.png";
import ornMin51 from "@/assets/images/orn-min-51.png";
import logoCover from "@/assets/images/logo-cover.png";
import ornMin6 from "@/assets/images/orn-min-6.png";
import ornMin7 from "@/assets/images/orn-min-7.png";
import ornMin8 from "@/assets/images/orn-min-8.png";
import ornMin9 from "@/assets/images/orn-min-9.png";
import { useEffect, useState } from "react";
import useMusicStore from "@/stores/musicStore";
import useTamuStore from "@/stores/tamuStore";

const index = () => {
  const { playMusic } = useMusicStore();
  const [isOpen, setIsOpen] = useState(false);
  const { tamu } = useTamuStore();
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "visible";
      playMusic();
    }
  }, [isOpen]);
  return (
    <section className={`top-cover ${isOpen ? "hide" : ""}`}>
      <div className="top-cover-inner">
        <div className="ornaments-wrapper">
          <div className="footnote--left-right-orn awan-1">
            <div
              className="image-wrap"
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <img src={awan} alt="Ornament" />
            </div>
            <div className="footnote--left-right-orn awan-2">
              <div
                className="image-wrap"
                data-aos="fade-left"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img src={awan} alt="Ornament" />
              </div>
            </div>
          </div>
          <div className="footnote--left-right-orn co-1">
            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
            >
              <img src={ornMin50} alt="Ornament" />
            </div>
          </div>
          {/* LEFT ORN */}
          <div className="footnote--left-right-orn lo-1">
            <div className="footnote--left-right-orn lo-11">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="900"
              >
                <img src={ornMin2} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-10">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="800"
              >
                <img src={ornMin44} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-9">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin41} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-4">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin42} alt="Ornament" />
              </div>
            </div>
            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
            >
              <img src={ornMin3} alt="Ornament" />
            </div>
            <div className="footnote--left-right-orn lo-2">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin5} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-3">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin4} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-5">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin37} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-6">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin1} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-7">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img src={ornMin35} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn lo-8">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img src={ornMin51} alt="Ornament" />
              </div>
            </div>
          </div>
          {/* RIGHT ORN */}
          <div className="footnote--left-right-orn ro-1">
            <div className="footnote--left-right-orn ro-11">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="900"
              >
                <img src={ornMin2} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-10">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="800"
              >
                <img src={ornMin44} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-9">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="700"
              >
                <img src={ornMin41} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-4">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin42} alt="Ornament" />
              </div>
            </div>
            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="600"
            >
              <img src={ornMin3} alt="Ornament" />
            </div>
            <div className="footnote--left-right-orn ro-2">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin5} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-3">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="500"
              >
                <img src={ornMin4} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-5">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin37} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-6">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <img src={ornMin1} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-7">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img src={ornMin35} alt="Ornament" />
              </div>
            </div>
            <div className="footnote--left-right-orn ro-8">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                <img src={ornMin51} alt="Ornament" />
              </div>
            </div>
          </div>
        </div>
        {/* GARDEN */}
        <div className="top-cover-garden">
          {/* DETAILS */}
          <div className="top-cover-details">
            <div className="ornaments-wrapper">
              <div className="top-cover-orn logo">
                <div
                  className="image-wrap"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                  data-aos-delay="300"
                  data-aos-anchor=".top-cover-details"
                >
                  <img src={logoCover} alt="Ornament" />
                </div>
              </div>
            </div>
            <div className="top-cover-details--content">
              <h1
                className="top-cover-title"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
                data-aos-anchor=".top-cover-details"
              >
                <span className="!wi-mr-3">Rizka</span> <span>&amp;</span>
                <span className="!wi-ml-3">Adhitya</span>
              </h1>
              <div
                className="link-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="300"
                data-aos-anchor=".top-cover-details"
              >
                <p className="!wi-text-[1.5rem]">Kepada Yth, Bapak/Ibu/Saudara/I.</p>
                <p className="!wi-text-[1.5rem]">{tamu.displayName}</p>
              
                <a
                  href="#"
                  className="link"
                  id="startToExplore"
                  onClick={() => setIsOpen(true)}
                >
                  Buka Undangan
                </a>
                <span className="wi-block wi-text-xs wi-text-primary">
                  *Mohon maaf apabila ada kesalahan penulisan nama dan gelar
                </span>
              </div>
            </div>
          </div>
        </div>
        <section
          className="effects nanda"
          data-aos="zoom-out"
          data-aos-duration="2000"
          data-aos-delay="500"
        >
          <div>
            <img src={ornMin6} alt="" />
          </div>
          <div>
            <img src={ornMin7} alt="" />
          </div>
          <div>
            <img src={ornMin8} alt="" />
          </div>
          <div>
            <img src={ornMin9} alt="" />
          </div>
        </section>
      </div>
    </section>
  );
};

export default index;
