import ornMin20 from '@/assets/images/orn-min-20.png'
import coverFrame from '@/assets/images/cover-frame.png'
import ornMin44 from '@/assets/images/orn-min-44.png'
import ornMin41 from '@/assets/images/orn-min-41.png'
import ornMin40 from '@/assets/images/orn-min-40.png'   
import ornMin38 from '@/assets/images/orn-min-38.png'
import ornMin39 from '@/assets/images/orn-min-39.png'
import ornMin15 from '@/assets/images/orn-min-15.png'
import ornMin16 from '@/assets/images/orn-min-16.png'
import ornMin21 from '@/assets/images/orn-min-21.png'
import ornMin25 from '@/assets/images/orn-min-25.png'
import logoCover from '@/assets/images/logo-cover.png'

const index = () => {
  return (
    <section className="cover-wrap">
    <div className="cover-inner">
        <div className="ornaments-wrapper">
            <div className="cover-orn cover-frame">
                <div className="cover-orn cover-top-right-3">
                    <div className="image-wrap" data-aos="zoom-out" data-aos-duration="1200" data-aos-delay="700">
                        <img src={ornMin20} alt="Ornament"/>
                    </div>
                </div>

                <div className="cover-orn cover-top-left-3">
                    <div className="image-wrap" data-aos="zoom-out" data-aos-duration="1200" data-aos-delay="700">
                        <img src={ornMin20} alt="Ornament"/>
                    </div>
                </div>


                <div className="bg-cover">
                    <div className="image-wrap" style={{position: 'absolute'}} data-aos="fade-down"
                        data-aos-duration="1200" data-aos-delay="700">
                    </div>
                </div>
                <div className="image-wrap" data-aos="zoom-in-up" data-aos-duration="1200" data-aos-delay="200">
                    <img src={coverFrame} alt="Ornament"/>
                </div>

                <div className="cover-orn cover-right-1">
                    <div className="cover-orn cover-right-7">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="900">
                            <img src={ornMin44} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-right-6">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="700">
                            <img src={ornMin41} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-right-5">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="500">
                            <img src={ornMin40} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-right-4">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="300">
                            <img src={ornMin38} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-right-3">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="300">
                            <img src={ornMin39} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="200">
                        <img src={ornMin15} alt="Ornament"/>
                    </div>

                    <div className="cover-orn cover-right-2">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="300">
                            <img src={ornMin16} alt="Ornament"/>
                        </div>
                    </div>
                </div>

                <div className="cover-orn cover-left-1">
                    <div className="cover-orn cover-left-7">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="900">
                            <img src={ornMin44} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-left-6">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="700">
                            <img src={ornMin41} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-left-5">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="500">
                            <img src={ornMin40} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-left-4">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="300">
                            <img src={ornMin38} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="cover-orn cover-left-3">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="300">
                            <img src={ornMin39} alt="Ornament"/>
                        </div>
                    </div>

                    <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="200">
                        <img src={ornMin15} alt="Ornament"/>
                    </div>

                    <div className="cover-orn cover-left-2">
                        <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200"
                            data-aos-delay="300">
                            <img src={ornMin16} alt="Ornament"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="cover-orn cover-top-right-1">
                <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="200">
                    <img src={ornMin21} alt="Ornament"/>
                </div>

                <div className="cover-orn cover-top-right-2">
                    <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="300">
                        <img src={ornMin25} alt="Ornament"/>
                    </div>
                </div>
            </div>

            <div className="cover-orn cover-top-left-1">
                <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="200">
                    <img src={ornMin21} alt="Ornament"/>
                </div>

                <div className="cover-orn cover-top-left-2">
                    <div className="image-wrap" data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="300">
                        <img src={ornMin25} alt="Ornament"/>
                    </div>
                </div>

            </div>


        </div>
        <div className="cover-logo">
            <div className="logo-wrap" data-aos="fade-down" data-aos-duration="1200" data-aos-delay="200">
                <img src={logoCover} alt="" className="logo"/>
            </div>
        </div>
        <div className="cover-details">
            <p className="top-text" data-aos="fade-down" data-aos-duration="1000">
                The Wedding Of </p>

            <h1 className="prime-title" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="200">
                <span>Rizka</span> <br/> <span>&amp;</span> <br/> <span>Adhitya</span>
            </h1>



        </div>
    </div>
</section>
  )
}

export default index