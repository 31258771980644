import TamuModel from "@/types/TamuModel";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import {
  db,
  collection,
  onSnapshot,
  // doc,
  // updateDoc,
  query,
  where,
} from "@/utility/firebase_options";
import { useEffect, useRef, useState } from "react";

const LihatWishModal = ({
  isModalLihatWishOpen,
  setIsModalLihatWishOpen,
  tamu,
}: {
  isModalLihatWishOpen: boolean;
  setIsModalLihatWishOpen: (value: boolean) => void;
  tamu?: TamuModel | null;
}) => {
  const [wish, setWish] = useState([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleOkLihatWish = () => {
    setIsModalLihatWishOpen(false);
  };

  const handleCancelLihatWish = () => {
    setIsModalLihatWishOpen(false);
  };

  useEffect(() => {
    if (isModalLihatWishOpen) {
      const unsubscribe = onSnapshot(
        tamu?.id
          ? query(collection(db, "ucapan"), where("tamuId", "==", tamu.id))
          : query(collection(db, "ucapan")),
        (snapshot) => {
          const data = snapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .sort(
              (a: any, b: any) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            ); // Sort by newest
          setWish(data as any);
        }
      );

      return () => {
        unsubscribe();
      };
    } else {
      setWish([]);
    }
  }, [isModalLihatWishOpen, tamu?.id]);

  return (
    <Modal
      title={
        <div className="wi-flex wi-items-center">
          <p className="!wi-text-white !wi-text-[1.5rem]">
            Ucapan {tamu?.displayName || "Semua Tamu"}
          </p>
        </div>
      }
      open={isModalLihatWishOpen}
      onOk={handleOkLihatWish}
      onCancel={handleCancelLihatWish}
      footer={null}
      width={450}
      closeIcon={<CloseOutlined className="wi-text-white" />}
      classNames={{
        body: "!wi-bg-tertiary",
        content: "!wi-bg-tertiary",
        header: "!wi-bg-tertiary !wi-text-white",
      }}
    >
      {wish.length > 0 ? (
        <div
          className="wi-flex wi-flex-col  wi-px-4 wi-mt-8"
          ref={containerRef}
        >
          {wish.map((item: any, index: number) => (
            <div
              key={index}
              className="comment-item aos-init aos-animate "
              id={`comment${index}`}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="comment-head">
                {tamu === null ? (
                  <h3 className="comment-name !wi-text-white">
                    {item.displayName} <i className="fas fa-check"></i>
                  </h3>
                ) : null}
                <small className="comment-date !wi-text-white">
                  {new Date(item.createdAt).toLocaleDateString()}
                </small>
              </div>
              <div className="comment-body">
                <p className="comment-caption !wi-text-white">{item.ucapan}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="wi-text-white">Tidak ada ucapan</p>
      )}
    </Modal>
  );
};

export default LihatWishModal;
