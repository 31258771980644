export const mempelai = [
  {
    id: "1",
    name: "Adhitya",
  },
  {
    id: "2",
    name: "Rizka",
  },
];

export const statusTamu = [
  {
    id: "1",
    name: "Keluarga",
  },
  {
    id: "2",
    name: "Teman",
  },
];
