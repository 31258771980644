import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "aos/dist/aos.css";
import "@/assets/css/custom-rizka-rachman.css";
import "@/assets/css/global.css";
import 'react-toastify/dist/ReactToastify.css';
import "lightgallery/css/lightgallery.css";
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import '@vidstack/react/player/styles/default/layouts/audio.css';
import "./index.css";

import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import { ToastContainer } from "react-toastify";
import { ConfigProvider } from "antd";

createRoot(document.getElementById("root")!).render(
  <StrictMode>
      <ConfigProvider
    theme={{
        token: {
          // Seed Token
          colorPrimary: "#e4bd75",

        // Alias Token
        
      },
      }}
    >
      <RouterProvider router={router} />
      <ToastContainer />
    </ConfigProvider>
  </StrictMode>
);
