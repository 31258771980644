import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";

import { Modal, Button } from "antd";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { db, doc, updateDoc } from "@/utility/firebase_options";
import TamuModel from "@/types/TamuModel";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { mempelai, statusTamu } from "@/constants/mempelai";

interface IEditTamuFormInput {
  displayName: string;
  type: string | null;
  tamuDari: string | null;
  pax: string;
}
const EditTamuModal = ({
  tamu,
  isModalEditOpen,
  setIsModalEditOpen,
}: {
  tamu: TamuModel;
  isModalEditOpen: boolean;
  setIsModalEditOpen: (value: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const schema = z.object({
    displayName: z.string().min(1, "Nama Tamu is required"),
    pax: z
      .string()
      .regex(/^\d+$/, "Pax/Orang must be a number")
      .min(1, "Pax/Orang is required"),
    tamuDari: z.enum(mempelai.map((item) => item.id) as [string, ...string[]]),
    type: z.enum(statusTamu.map((item) => item.id) as [string, ...string[]]),
  });

  const {
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm<IEditTamuFormInput>({
    defaultValues: {
      displayName: tamu.displayName,
      pax: tamu.pax.toString(),
      tamuDari: tamu.tamuDari,
      type: tamu.type,
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: IEditTamuFormInput) => {
    setLoading(true);
    const docRef = doc(db, "tamu", tamu.id ?? "");
    updateDoc(docRef, {
      displayName: data.displayName,
      pax: parseInt(data.pax),
      tamuDari: data.tamuDari,
      type: data.type,
      updatedAt: new Date().toISOString(),
    })
      .then(() => {
        setLoading(false);
        toast.success("Data Tamu berhasil diubah");
        reset();
        handleOkEdit();
      })
      .catch((_) => {
        setLoading(false);
        toast.error("Data Tamu gagal diubah");
      });
  };
  const handleOkEdit = () => {
    setIsModalEditOpen(false);
  };

  const handleCancelEdit = () => {
    setIsModalEditOpen(false);
  };
  useEffect(() => {
    if (isModalEditOpen) {
      setValue("displayName", tamu.displayName);
      setValue("pax", tamu.pax.toString());
      setValue("tamuDari", tamu.tamuDari);
      setValue("type", tamu.type);
    } else {
      reset();
    }
  }, [isModalEditOpen, tamu, setValue, reset]);
  return (
    <Modal
      title={<p className="!wi-text-white !wi-text-[1.5rem]">Ubah Tamu </p>}
      open={isModalEditOpen}
      onOk={handleOkEdit}
      onCancel={handleCancelEdit}
      footer={null}
      width={400}
      closeIcon={<CloseOutlined className="wi-text-white" />}
      classNames={{
        body: "!wi-bg-tertiary",
        content: "!wi-bg-tertiary",
        header: "!wi-bg-tertiary !wi-text-white",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-4">
          <div className="wi-flex wi-flex-row wi-justify-between wi-items-center">
            <p className="!wi-text-white !wi-text-base">Nama Tamu:</p>
            {/* <span className="!wi-text-white !wi-text-xs">(*wajib)</span> */}
          </div>
          <input
            disabled={loading}
            placeholder="Masukkan Nama Tamu"
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full  wi-border-none wi-bg-primary  wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("displayName")}
          />
          {errors.displayName && (
            <p className="!wi-text-red-500 !wi-text-xs">
              {errors.displayName.message}
            </p>
          )}
        </div>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-2">
          <div className="wi-flex wi-flex-row wi-justify-between wi-items-center">
            <p className="!wi-text-white !wi-text-base">Pax/Orang:</p>
            {/* <span className="!wi-text-white !wi-text-xs">(*wajib)</span> */}
          </div>

          <input
            disabled={loading}
            placeholder="Masukkan Pax"
            {...register("pax")}
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full  wi-border-none wi-bg-primary  wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
          />
          {errors.pax && (
            <p className="!wi-text-red-500 !wi-text-xs">{errors.pax.message}</p>
          )}
          <p className="!wi-text-white !wi-text-xs">
            *Pax/Orang adalah jumlah anggota keluarga dari tamu yang akan hadir
            di acara
          </p>
        </div>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-2">
          <p className="!wi-text-white !wi-text-base">Status:</p>
          <select
            disabled={loading}
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full wi-border-none wi-bg-primary wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("type")}
          >
            <option value="">-- Pilih --</option>
            {statusTamu.map((item, index) => (
              <option
                key={index}
                value={item.id}
                className="wi-bg-primary wi-text-white wi-hover:!wi-bg-primary"
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-2">
          <p className="!wi-text-white !wi-text-base">Dari:</p>
          <select
            disabled={loading}
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full wi-border-none wi-bg-primary wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("tamuDari")}
          >
            <option value="" selected={tamu.tamuDari === ""}>
              -- Pilih --
            </option>
            {mempelai.map((item, index) => (
              <option
                key={index}
                value={item.id}
                className="wi-bg-primary wi-text-white wi-hover:!wi-bg-primary"
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>

        <Button
          disabled={loading}
          type="primary"
          className="wi-w-full wi-mt-4 !wi-bg-green-500 !wi-text-white wi-py-3"
          size="large"
          htmlType="submit"
        >
          {loading ? (
            <LoadingOutlined className="wi-text-white wi-animate-spin wi-text-2xl" />
          ) : (
            "Ubah"
          )}
        </Button>
      </form>
    </Modal>
  );
};

export default EditTamuModal;
