import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { zodResolver } from "@hookform/resolvers/zod";

import { Modal, Button } from "antd";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { db, collection, addDoc } from "@/utility/firebase_options";
import { toast } from "react-toastify";
import { useState } from "react";
import { mempelai, statusTamu } from "@/constants/mempelai";
const TambahTamuModal = ({
  isModalTambahOpen,
  setIsModalTambahOpen,
}: {
  isModalTambahOpen: boolean;
  setIsModalTambahOpen: (value: boolean) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const schema = z.object({
    displayName: z.string().min(1, "Nama Tamu is required"),
    pax: z
      .string()
      .regex(/^\d+$/, "Pax/Orang must be a number")
      .min(1, "Pax/Orang is required"),
    tamuDari: z.enum(mempelai.map((item) => item.id) as [string, ...string[]]),
    type: z.enum(statusTamu.map((item) => item.id) as [string, ...string[]]),
  });

  const { handleSubmit, reset, register, formState: { errors } } = useForm({
    defaultValues: {
      displayName: "",
      pax: 0,
      type: "",
      tamuDari: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: any) => {
    setLoading(true);
    const docRef = collection(db, "tamu");
    addDoc(docRef, {
      displayName: data.displayName,
      pax: parseInt(data.pax),
      type: data.type,
      tamuDari: data.tamuDari,
      confirmedHadir: false,
      undanganTerkirim: false,
      hadir: false,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    })
      .then(() => {
        setLoading(false);
        reset();
        handleOkTambah();
        toast.success("Data Tamu berhasil ditambahkan");
      })
      .catch((_) => {
        setLoading(false);
        toast.error("Data Tamu gagal ditambahkan");
      });
  };
  const handleOkTambah = () => {
    setIsModalTambahOpen(false);
  };

  const handleCancelTambah = () => {
    setIsModalTambahOpen(false);
  };

  return (
    <Modal
      title={<p className="!wi-text-white !wi-text-[1.5rem]">Tambah Tamu</p>}
      open={isModalTambahOpen}
      onOk={handleOkTambah}
      onCancel={handleCancelTambah}
      footer={null}
      width={400}
      closeIcon={<CloseOutlined className="wi-text-white" />}
      classNames={{
        body: "!wi-bg-tertiary",
        content: "!wi-bg-tertiary",
        header: "!wi-bg-tertiary !wi-text-white",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-4">
          <div className="wi-flex wi-flex-row wi-justify-between wi-items-center">
            <p className="!wi-text-white !wi-text-base">Nama Tamu:</p>
            {/* <span className="!wi-text-white !wi-text-xs">(*wajib)</span> */}
          </div>
          <input
            disabled={loading}
            placeholder="Masukkan Nama Tamu"
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full  wi-border-none wi-bg-primary  wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("displayName")}
          />
          {errors.displayName && (
            <p className="!wi-text-red-500 !wi-text-xs">
              {errors.displayName.message}
            </p>
          )}
        </div>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-2">
          <div className="wi-flex wi-flex-row wi-justify-between wi-items-center">
            <p className="!wi-text-white !wi-text-base">Pax/Orang:</p>
            {/* <span className="!wi-text-white !wi-text-xs">(*wajib)</span> */}
          </div>
          <input
            disabled={loading}
            placeholder="Masukkan Pax"
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full  wi-border-none wi-bg-primary  wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("pax")}
          />
          {errors.pax && (
            <p className="!wi-text-red-500 !wi-text-xs">{errors.pax.message}</p>
          )}
          <p className="!wi-text-white !wi-text-xs">
            *Pax/Orang adalah jumlah anggota keluarga dari tamu yang akan hadir
            di acara
          </p>
        </div>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-2">
          <p className="!wi-text-white !wi-text-base">Status:</p>
          <select
            disabled={loading}
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full wi-border-none wi-bg-primary wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("type")}
          >
            <option value="">-- Pilih --</option>
            {statusTamu.map((item) => (
              <option
                value={item.id}
                className="wi-bg-primary wi-text-white wi-hover:!wi-bg-primary"
              >
                {item.name}
              </option>
            ))}
          </select>
          {errors.type && (
            <p className="!wi-text-red-500 !wi-text-xs">{errors.type.message}</p>
          )}
        </div>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-2">
          <p className="!wi-text-white !wi-text-base">Dari:</p>
          <select
            disabled={loading}
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full wi-border-none wi-bg-primary wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("tamuDari")}
          >
            <option value="">-- Pilih --</option>
            {mempelai.map((item) => (
              <option
                value={item.id}
                className="wi-bg-primary wi-text-white wi-hover:!wi-bg-primary"
              >
                {item.name}
              </option>
            ))}
          </select>
          {errors.tamuDari && (
            <p className="!wi-text-red-500 !wi-text-xs">{errors.tamuDari.message}</p>
          )}
        </div>

        <Button
          disabled={loading}
          type="primary"
          className="wi-w-full wi-mt-4 !wi-bg-green-500 !wi-text-white wi-py-3"
          size="large"
          htmlType="submit"
        >
          {loading ? (
            <LoadingOutlined className="wi-text-white wi-animate-spin wi-text-2xl" />
          ) : (
            "Tambah"
          )}
        </Button>
      </form>
    </Modal>
  );
};

export default TambahTamuModal;
