import TamuModel from "@/types/TamuModel";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { db, deleteDoc, doc } from "@/utility/firebase_options";
import { toast } from "react-toastify";

const HapusTamuModal = ({
  isModalHapusTamuOpen,
  setIsModalHapusTamuOpen,
  tamu,
}: {
  isModalHapusTamuOpen: boolean;
  setIsModalHapusTamuOpen: (value: boolean) => void;
  tamu: TamuModel;
}) => {
  const [loading, setLoading] = useState(false);
  const schema = z.object({
    kodePin: z.string().min(1, "Kode Pin is required"),
  });

  const { handleSubmit, reset, register } = useForm({
    defaultValues: {
      kodePin: "",
    },
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: any) => {
    if (data.kodePin !== import.meta.env.VITE_KOD_PIN) {
      toast.error("Kode Pin salah");
      return;
    }
    setLoading(true);
    const docRef = doc(db, "tamu", tamu.id ?? "");
    deleteDoc(docRef)
      .then(() => {
        setLoading(false);
        reset();
        handleOkHapusTamu();
        toast.success(`Data Tamu ${tamu.displayName} berhasil dihapus`);
      })
      .catch((_) => {
        setLoading(false);
        toast.error(`Data Tamu ${tamu.displayName} gagal dihapus`);
      });
  };
  const handleOkHapusTamu = () => {
    setIsModalHapusTamuOpen(false);
  };

  const handleCancelHapusTamu = () => {
    setIsModalHapusTamuOpen(false);
  };

  return (
    <Modal
      title={<p className="!wi-text-white !wi-text-[1.5rem]">Hapus Tamu</p>}
      open={isModalHapusTamuOpen}
      onOk={handleOkHapusTamu}
      onCancel={handleCancelHapusTamu}
      footer={null}
      width={400}
      closeIcon={<CloseOutlined className="wi-text-white" />}
      classNames={{
        body: "!wi-bg-tertiary",
        content: "!wi-bg-tertiary",
        header: "!wi-bg-tertiary !wi-text-white",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="wi-flex wi-flex-col  wi-justify-center wi-gap-2 wi-w-full wi-mt-2">
          <p className="!wi-text-white !wi-text-base">Kode Pin:</p>
          <input
            disabled={loading}
            placeholder="Masukkan Kode Pin"
            type="password"
            className="wi-px-4 wi-rounded-lg wi-outline-none wi-py-3 wi-w-full  wi-border-none wi-bg-primary  wi-shadow-sm placeholder:!wi-text-white wi-text-white focus:!wi-border-none focus:!wi-ring-0 focus:!wi-ring-offset-0 focus:!wi-ring-offset-transparent focus:!wi-bg-primary hover:!wi-bg-primary "
            {...register("kodePin")}
          />
        </div>
        <Button
          disabled={loading}
          type="primary"
          className="wi-w-full wi-mt-4 !wi-bg-green-500 !wi-text-white wi-py-3"
          size="large"
          htmlType="submit"
        >
          {loading ? (
            <LoadingOutlined className="wi-text-white wi-animate-spin wi-text-2xl" />
          ) : (
            "Hapus"
          )}
        </Button>
      </form>
    </Modal>
  );
};

export default HapusTamuModal;
