import ornMin19 from "@/assets/images/orn-min-19.png";
import ornMin30 from "@/assets/images/orn-min-30.png";
import ornMin3 from "@/assets/images/orn-min-3.png";
import ornMin31 from "@/assets/images/orn-min-31.png";
import wayang from "@/assets/images/wayang.png";
import ornMin32 from "@/assets/images/orn-min-32.png";
import ornMin1 from "@/assets/images/orn-min-1.png";
import ornMin33 from "@/assets/images/orn-min-33.png";
import { listRekening } from "@/constants/rekening";
import { toast } from "react-toastify";
const index = () => {
  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Nomor rekening berhasil disalin!", {
          position: "top-right",
          style: {
            backgroundColor: "#591d1f",
            color: "#e4bd75",
          },
        }); // Ganti alert dengan toast
      },
      (err) => {
        console.error("Gagal menyalin teks: ", err);
      }
    );
  };
  return (
    <section className="wedding-gift-wrap">
      <div className="wedding-gift-inner">
        <div className="ornaments-wrapper">
          <div className="wedding-gift-orn right-1">
            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img src={ornMin19} alt="Ornament" />
            </div>

            <div className="wedding-gift-orn right-2">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img src={ornMin30} alt="Ornament" />
              </div>
            </div>

            <div className="wedding-gift-orn right-3">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img src={ornMin3} alt="Ornament" />
              </div>
            </div>

            <div className="wedding-gift-orn right-4">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img src={ornMin31} alt="Ornament" />
              </div>
            </div>
          </div>

          <div className="wedding-gift-orn right-8">
            <div className="wedding-gift-orn right-5">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img src={wayang} alt="Ornament" />
              </div>
            </div>

            <div className="wedding-gift-orn right-6">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img src={ornMin32} alt="Ornament" />
              </div>
            </div>

            <div className="wedding-gift-orn right-7">
              <div
                className="image-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="200"
              >
                <img src={ornMin1} alt="Ornament" />
              </div>
            </div>

            <div
              className="image-wrap"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <img src={ornMin33} alt="Ornament" />
            </div>
          </div>
        </div>
        <div
          className="gift-frame"
          data-aos="zoom-in"
          data-aos-duration="1200"
          data-aos-delay="700"
        >
          <div className="frame-wrap">
            <div className="wedding-gift-head">
              <h1
                className="wedding-gift-title"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Amplop Digital
              </h1>
              <p
                className="wedding-gift-description"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Doa restu dan kehadiran Anda di pernikahan kami sudah cukup bagi
                kami. Namun, jika Anda ingin memberikan hadiah, kami menyediakan
                Amplop Digital untuk memudahkan Anda. Terima kasih:
              </p>
            </div>

            <div className="wedding-gift-body">
              <div className="wedding-gift-bank-wrap wi-flex wi-flex-col wi-gap-4">
                {listRekening.map((rekening, index) => (
                  <div key={index} className="bank-item" id={`savingBook${index}`}>
                    <div className="bank-detail">
                      <h3
                        className="bank-name"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        data-aos-delay="700"
                      >
                        {rekening.name}
                      </h3>
                      <p
                        className="bank-account-name"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        data-aos-delay="800"
                      >
                        {rekening.number}
                      </p>
                      <p
                        className="bank-account-name"
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                        data-aos-delay="900"
                      >
                        Pemilik Rekening : <br />
                        {rekening.owner}
                      </p>
                    </div>
                    <div
                      className="bank-copy-wrap"
                      data-aos="zoom-in"
                      data-aos-duration="1000"
                      data-aos-delay="1000"
                    >
                      <button
                        type="button"
                        className="bank-copy"
                        onClick={() => copyToClipboard(rekening.number)}
                      >
                        Salin Nomor Rekening
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default index;
