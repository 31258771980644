import useMusicStore from "@/stores/musicStore";

const index = () => {
  const { playing, playMusic, pauseMusic } = useMusicStore();
 

  return (
    <section
      className="music-outer"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="300"
    >
      <div
        onClick={playing ? pauseMusic : playMusic}
        className={`music-box auto ${playing ? "playing" : ""}`}
        style={{ animationPlayState: playing ? "running" : "paused" }}
        id="music-box"
      ></div>
    </section>
  );
};

export default index;
