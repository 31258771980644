import { createBrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import ScanQRCode from "./components/QRScan";
import MiniPanel from "./components/mini_panel";
import TamuList from "./components/mini_panel/pages/tamu";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
 
  {
    path: `/${import.meta.env.VITE_MINI_PANEL}`,
    element: <MiniPanel />,
    children: [
      
      {
        index: true,
        element: <Navigate to="tamu" replace />,
      },
      {
        path: "tamu",
        element: <TamuList />,
      },
      {
        path: `scan`,
        element: <ScanQRCode />,
      },
    ],
  },
]);
